import { useState, useEffect } from 'react'
//ASSETS
import { ReactComponent as LightLogo } from '../assets/light_logo_WP.svg'
import { ReactComponent as DarkLogo } from '../assets/dark_logo_WP.svg'
//COMPONENT
import { UserAvatar, CustomModal } from './index'
import { MenuNotifs, AllNotifsModal } from './Notifications/index'
//MATERIAL UI
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Badge,
  InputBase,
  alpha,
  Typography,
  ClickAwayListener,
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
//ICONS
import {
  MdOutlineNotificationsNone,
  MdBrightness4,
  MdBrightness7,
  MdMenu,
  MdLogout,
  MdNotifications,
  MdSearch,
} from 'react-icons/md'
import { BsCameraReels } from 'react-icons/bs'
import { RiFileEditLine, RiShoppingCart2Line } from 'react-icons/ri'
// TYPES
import { CourseType, NotificationType, ProductType, ProjectType, RoleCategoryEnum } from '../../../lib/sharedTypes'
// REDUX
import { logOutAction } from '../store/reducers/authReducer'
import { resetUser } from '../store/reducers/userReducer'
import { useAppDispatch, useAppSelector } from '../hooks/reducerHooks'
import { toggleTheme } from '../store/reducers/userReducer'
//REACT-ROUTER
import { useNavigate } from 'react-router-dom'
//GQL
import { NOTIFICATIONS_QUERY } from '../gql/queries'
import { useQuery } from '@apollo/client'
//GQL
import { GLOBAL_SEARCH_QUERY } from '../gql/queries'
import { useLazyQuery } from '@apollo/client'

type MenuPropsType = {
  setDrawerOpen: (arg: boolean) => void
  drawerOpen: boolean
  mdUp: boolean
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.default,
  },
  menuLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.text.disabled}`,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create('width'),
    width: '400px',
  },
  inputFocused: {
    border: `solid 1px ${theme.palette.primary.main}`,
  },
  result: {
    zIndex: 10000,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    padding: theme.spacing(1),
    cursor: 'pointer',
    background: theme.palette.background.paper,
    '&:hover': {
      background: theme.palette.background.default,
    },
  },
}))

export default function NavBar({ setDrawerOpen, drawerOpen, mdUp }: MenuPropsType) {
  const theme = useTheme()
  const user = useAppSelector((state) => state.user.user)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const [anchorElAccount, setAnchorElAccount] = useState<null | HTMLElement>(null)
  const [anchorElNotifs, setAnchorElNotifs] = useState<null | HTMLElement>(null)
  const [menuOpenAccount, setMenuOpenAccount] = useState<boolean>(false)
  const [menuOpenNotifs, setMenuOpenNotifs] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<Array<CourseType | ProductType | ProjectType>>()
  const [searchFocused, setSearchFocused] = useState(false)
  const { data: { notifications = [] } = {}, refetch } = useQuery<{
    notificationsCount: number
    notifications: NotificationType[]
  }>(NOTIFICATIONS_QUERY, {
    variables: {
      where: {
        target: {
          id: {
            eq: user && parseInt(`${user.id}`),
          },
        },
      },
      order: {
        sort: 'DESC',
        field: 'sent_at',
      },
      take: 20,
    },
  })

  useEffect(() => {
    setInterval(async () => {
      await refetch()
    }, 30000)
  }, [])

  const openMenuNotifs = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifs(event.currentTarget)
    setMenuOpenNotifs(true)
  }

  const openMenuAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccount(event.currentTarget)
    setMenuOpenAccount(true)
  }

  const handleCloseAccount = () => {
    setAnchorElAccount(null)
    setMenuOpenAccount(false)
  }

  const handleCloseNotifs = () => {
    setAnchorElNotifs(null)
    setMenuOpenNotifs(false)
  }

  const [globalSearchQuery] = useLazyQuery<{
    global: { courses: CourseType[]; products: ProductType[]; projects: ProjectType[] }
  }>(GLOBAL_SEARCH_QUERY, {
    onCompleted(data) {
      let results: Array<CourseType | ProductType | ProjectType>
      if (user?.roles.map((role) => role.category).includes(RoleCategoryEnum.EDITOR)) {
        results = data.global.projects
      } else {
        results = data.global.courses
        results = results.concat(data.global.projects)
        results = results.concat(data.global.products)
      }
      results.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
      setSearchResults(results)
    },
  })

  const search = async (searchQuery: string): Promise<void> => {
    if (searchQuery.length === 0) {
      setSearchResults([])
    } else {
      await globalSearchQuery({
        variables: {
          searchQuery: searchQuery,
        },
      })
    }
  }

  const getResultRow = (result: CourseType | ProductType | ProjectType, index: number) => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const rowType = result.__typename

    switch (rowType) {
      case 'Product':
        return (
          <Grid
            container
            alignItems="center"
            key={index}
            wrap="nowrap"
            className={classes.result}
            onClick={() => {
              navigate(`/products/${result.code_name}`)
            }}
          >
            <Grid item style={{ marginRight: theme.spacing(1), whiteSpace: 'nowrap' }}>
              <RiShoppingCart2Line size="18px" style={{ color: theme.palette.text.primary }} />{' '}
            </Grid>
            <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.text.primary,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {(result as ProductType).title ? (result as ProductType).title : '-'} | {result.code_name}
              </Typography>
            </Grid>
          </Grid>
        )
      case 'Project':
        return (
          <Grid
            container
            alignItems="center"
            key={index}
            wrap="nowrap"
            className={classes.result}
            onClick={() => {
              navigate(`/projects/${result.code_name}`)
            }}
          >
            <Grid
              item
              style={{
                marginRight: theme.spacing(1),

                whiteSpace: 'nowrap',
              }}
            >
              <BsCameraReels size="18px" style={{ color: theme.palette.text.primary }} />
            </Grid>
            <Grid
              item
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.text.primary,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {(result as ProjectType).name ? (result as ProjectType).name : '-'} | {result.code_name}
              </Typography>
            </Grid>
          </Grid>
        )
      case 'Course':
        return (
          <Grid
            container
            alignItems="center"
            key={index}
            wrap="nowrap"
            className={classes.result}
            onClick={() => {
              navigate(`/formations/${result.code_name}`)
            }}
          >
            <Grid item style={{ marginRight: theme.spacing(1), whiteSpace: 'nowrap' }}>
              <RiFileEditLine size="18px" style={{ color: theme.palette.text.primary }} />
            </Grid>
            <Grid item style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.text.primary,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {(result as CourseType).name ? (result as CourseType).name : '-'} | {result.code_name}
              </Typography>
            </Grid>
          </Grid>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          disableGutters
          style={{
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
          }}
        >
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <IconButton
                  edge="start"
                  aria-label="menu"
                  onClick={() => {
                    setDrawerOpen(!drawerOpen)
                  }}
                >
                  <MdMenu />
                </IconButton>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.menuLogo}
                    style={{ width: mdUp ? '100%' : 125 }}
                    onClick={(e: React.MouseEvent) => {
                      if (e.metaKey || e.ctrlKey) {
                        window.open('/', '_blank')
                      } else {
                        navigate('/')
                      }
                    }}
                  >
                    {theme.palette.type === 'light' ? <LightLogo /> : <DarkLogo />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {mdUp && (
              <Grid>
                <Grid className={classes.search}>
                  <Grid className={classes.searchIcon}>
                    <MdSearch size="22" style={{ color: theme.palette.text.disabled }} />
                  </Grid>
                  <ClickAwayListener
                    onClickAway={() => {
                      setSearchFocused(false)
                    }}
                  >
                    <InputBase
                      placeholder="Rechercher..."
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                        focused: classes.inputFocused,
                      }}
                      onFocus={() => {
                        setSearchFocused(true)
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={async (e) => {
                        await search(e.target.value)
                      }}
                    />
                  </ClickAwayListener>
                  <Grid
                    style={{
                      borderRadius: theme.shape.borderRadius,
                      display: searchFocused && searchResults ? 'block' : 'none',
                      width: 456,
                      maxHeight: 250,
                      overflow: 'scroll',
                      position: 'absolute',
                      background: theme.palette.background.default,
                    }}
                  >
                    {searchResults?.map((result, index) => {
                      return getResultRow(result, index)
                    })}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                  <IconButton
                    id="icon-notifs"
                    disabled={notifications?.length === 0}
                    onClick={(event) => {
                      openMenuNotifs(event)
                    }}
                  >
                    <Badge
                      badgeContent={notifications?.filter((n) => n.read_at === null)?.length ?? 0}
                      max={9}
                      overlap="rectangular"
                      color="primary"
                    >
                      {menuOpenNotifs ? <MdNotifications size="26" /> : <MdOutlineNotificationsNone size="26" />}
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    id="icon-account"
                    edge="start"
                    aria-label="menu"
                    onClick={(event) => {
                      openMenuAccount(event)
                    }}
                  >
                    {user && <UserAvatar user={user} />}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <MenuNotifs
        notifications={notifications}
        refetch={refetch}
        anchorEl={anchorElNotifs}
        handleClose={handleCloseNotifs}
        open={menuOpenNotifs}
        setIsModalOpen={setIsModalOpen}
      />
      <CustomModal
        title="Toutes mes notifications"
        animation="slide-up"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      >
        <AllNotifsModal
          handleClose={() => {
            setIsModalOpen(false)
          }}
        />
      </CustomModal>

      <Menu
        disableScrollLock={true}
        anchorEl={anchorElAccount}
        id="account-menu"
        open={menuOpenAccount}
        PaperProps={{
          elevation: 0,
          style: {
            marginTop: '40px',
            minWidth: '200px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(user0,0,0,0.32))',
          },
        }}
        onClose={handleCloseAccount}
      >
        <MenuItem
          onClick={(e: React.MouseEvent) => {
            handleCloseAccount()
            if (e.metaKey || e.ctrlKey) {
              window.open('/profile', '_blank')
            } else {
              navigate('/profile')
            }
          }}
        >
          <ListItemIcon>{user && <UserAvatar user={user} />}</ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            handleCloseNotifs()
            dispatch(toggleTheme())
          }}
        >
          <ListItemIcon>
            {theme.palette.type === 'dark' ? <MdBrightness7 size="32" /> : <MdBrightness4 size="32" />}
          </ListItemIcon>
          <ListItemText> {theme.palette.type === 'dark' ? 'Clair' : 'Sombre'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseNotifs()
            dispatch(resetUser())
            dispatch(logOutAction())
          }}
        >
          <ListItemIcon>
            <MdLogout size="32" />
          </ListItemIcon>
          <ListItemText>Se déconnecter</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
