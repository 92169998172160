//REACT
import { useCallback, useEffect, useState, useRef } from 'react'
//COMPONENTS
import { ImportCoursePartsForm } from '../../components/Forms/course'
import { Tree, LessonCardShort, AddPartModal, IntroEval } from '../../components/Lesson'
import { Error, Loading, CustomModal } from '../../components'
//REACT ICONS
import { MdSearch, MdMoreVert } from 'react-icons/md'
//MATERIAL UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  useTheme,
  Button,
  FormControl,
  Select,
  MenuItem,
  Menu,
} from '@material-ui/core'
//DND
import update from 'immutability-helper'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// ROUTER
import { useParams } from 'react-router'
// GRAPHQL
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import {
  COURSE_PLAN_QUERY,
  COURSE_LESSONS_PLAN_QUERY,
  COURSES_PLAN_QUERY,
  DOWNLOAD_COURSE_PLAN_EXCEL,
} from '../../gql/queries'
import { UPDATE_COURSE_PARTS_MUTATION, UPDATE_COURSE_MUTATION } from '../../gql/mutations'
// TYPES
import { CoursePartType, CourseType, LessonType } from '../../../../lib/sharedTypes'
//UTILS
import { handleError } from '../../utils/handleError'
import { useAlert } from '../../hooks'
import { isAuthorized, isCourseManager, secondsToDHMS } from '../../utils/misc'
//ANIMATION
import { animate } from 'motion'
import { sleep } from '../../utils/misc'

const Plan = () => {
  const { code_name } = useParams()
  const [totalDuration, setTotalDuration] = useState<number>(0)
  const [selectedCodeName, setSelectedCodeName] = useState<string>('')
  const [setAlert] = useAlert()
  const theme = useTheme()
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState(0)
  const [isPartModalOpen, setIsPartModalOpen] = useState<boolean>(false)
  const [expandAll, setExpandAll] = useState<boolean>(true)
  const [introduction, setIntroduction] = useState<LessonType | null>()
  const [evaluation, setEvaluation] = useState<LessonType | null>()
  const [parts, setParts] = useState<CoursePartType[]>([])
  const [lessons, setLessons] = useState<LessonType[]>([])
  const [lessonType, setLessonType] = useState('Tout')
  const [filteredLessons, setFilteredLessons] = useState<LessonType[]>([])
  const [usedLessonsIds, setUsedLessonsIds] = useState<string[]>([])
  const [courseLessonsQuery] = useLazyQuery<{ courses: CourseType[] }>(COURSE_LESSONS_PLAN_QUERY)
  const [importModal, setImportModal] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [downloadCoursePlanExcel] = useLazyQuery<{
    downloadCoursePlanExcel: string
  }>(DOWNLOAD_COURSE_PLAN_EXCEL, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    onCompleted(data) {
      window.open(data.downloadCoursePlanExcel, '_blank')
    },
  })

  const {
    loading,
    error,
    data: { course } = {},
    refetch,
  } = useQuery<{
    course: CourseType
  }>(COURSE_PLAN_QUERY, {
    variables: {
      where: {
        code_name: {
          eq: code_name,
        },
      },
      order: {
        field: 'position',
        sort: 'ASC',
      },
    },
  })

  const { data: { courses = [] } = {} } = useQuery<{
    courses: CourseType[]
    coursesCount: number
  }>(COURSES_PLAN_QUERY, {
    variables: {
      order: {
        field: 'code_name',
        sort: 'ASC',
      },
    },
  })

  useEffect(() => {
    setLessons([])
    setFilteredLessons([])
    const course = courses.find((course) => course.code_name === selectedCodeName)
    const codeNames = [selectedCodeName]
    if (course) {
      if (course.sub_courses) {
        course.sub_courses.map((sub_course) => codeNames.push(sub_course.code_name))
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCourseLessons(codeNames)
  }, [selectedCodeName])

  const [updateCoursePartsMutation] = useMutation<{
    UPDATE_COURSE_PARTS_MUTATION: CoursePartType[]
  }>(UPDATE_COURSE_PARTS_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
    async onCompleted() {
      setAlert({ severity: 'success', content: `Plan mis-à-jour` })
      await refetch()
    },
  })

  const [updateCourse] = useMutation<{ updateCourse: CourseType }>(UPDATE_COURSE_MUTATION, {
    onError(error) {
      const msg = handleError(error)
      setAlert({ severity: 'error', content: msg })
    },
  })

  const handleSubmit = async () => {
    const cb = (part: CoursePartType, index: number): Record<string, any> => {
      return {
        id: typeof part.id === 'string' ? part.id : undefined,
        name: part.name,
        duration: part.duration,
        position: index,
        lesson: { id: part?.lesson?.id },
        sub_parts: part.sub_parts
          ? part.sub_parts.map((part, index) => {
              return cb(part, index)
            })
          : [],
      }
    }

    const inputCourseParts = parts.map((part, index) => {
      return cb(part, index)
    })

    const inputCourse: Record<string, any> = {
      id: course?.id,
      introduction: { id: introduction?.id },
      evaluation: { id: evaluation?.id },
    }

    await updateCourse({ variables: { input: inputCourse } })
    await updateCoursePartsMutation({
      variables: { input: { course: { id: course?.id }, parts: inputCourseParts } },
    })
  }

  useEffect(() => {
    if (mainContainerRef.current && course) {
      const container = mainContainerRef.current.getBoundingClientRect()
      setMaxHeight(container.top + 80)
    }
  }, [mainContainerRef, course])

  useEffect(() => {
    if (course) {
      setIntroduction(course.introduction)
      setEvaluation(course.evaluation)
      setParts(course.parts)
      let planDuration = 0
      course.parts.map((part) => {
        part.sub_parts.map((sub_part) => {
          sub_part.sub_parts.map((ss_part) => {
            if (ss_part?.lesson?.video?.file_duration) {
              planDuration += ss_part.lesson.video.file_duration
            }
          })
        })
      })
      setTotalDuration(planDuration)
      if (selectedCodeName) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getCourseLessons([selectedCodeName])
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getCourseLessons([course.code_name])
      }
    }
  }, [course])

  const getCourseLessons = async (codeNames: string[]): Promise<void> => {
    const { data } = await courseLessonsQuery({
      variables: {
        where: {
          code_name: {
            in: codeNames,
          },
        },
      },
    })

    if (data) {
      const lessons = data.courses.reduce((acc: LessonType[], el: CourseType) => {
        acc = acc.concat(el.lessons)
        return acc
      }, [])
      setLessons(lessons)
      setFilteredLessons(lessons)
    }
  }

  const searchLessons = (search: string) => {
    let courseLessons
    if (search.startsWith('#')) {
      courseLessons = lessons
        .filter((lesson) => {
          return lesson.video !== null
        })
        .filter((lesson) => {
          return lesson.video.video_set.index.toString() === search.replace('#', '')
        })
        .sort((a, b) => {
          return a.video.number - b.video.number
        })
    } else {
      courseLessons = lessons.filter((lesson) => {
        return lesson.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      })
    }
    setFilteredLessons(courseLessons)
  }

  const getPart = useCallback(
    (part: CoursePartType) => {
      return parts.find(
        (pa) =>
          pa.id === part.id ||
          pa.sub_parts.some((sp) => sp.id === part.id) ||
          pa.sub_parts.some((sp) => sp.sub_parts.some((ssp) => ssp.id === part.id)),
      )
    },
    [parts],
  )

  const getChapter = useCallback(
    (part: CoursePartType) => {
      return parts
        .map((mainPart) => {
          return mainPart.sub_parts.find((sp) => sp.id === part.id || sp.sub_parts.some((ssp) => ssp.id === part.id))
        })
        .filter((p) => p)[0]
    },
    [parts],
  )

  const movePart = useCallback(
    (part: CoursePartType, target: CoursePartType) => {
      const targetPart = getPart(target)
      const itemPart = getPart(part)
      if (!targetPart || !itemPart) {
        console.error('Unknown part')
        return
      }
      const targetPartIndex = parts.indexOf(targetPart)
      const itemPartIndex = parts.indexOf(itemPart)
      if (targetPartIndex === itemPartIndex) {
        //SAME PART
        const targetChapter = getChapter(target)
        const itemChapter = getChapter(part)
        if (!targetChapter || !itemChapter) {
          console.error('Unknown chapter')
          return
        }

        const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
        const itemChapterIndex = itemPart.sub_parts.indexOf(itemChapter)

        if (targetChapterIndex !== itemChapterIndex) {
          //DIFFERENT CHAPTER
          const itemLessonIndex = itemChapter?.sub_parts.indexOf(part)
          setParts((prevParts) =>
            update(prevParts, {
              [targetPartIndex]: {
                sub_parts: {
                  [targetChapterIndex]: {
                    sub_parts: {
                      $splice: [[0, 0, part]],
                    },
                  },
                },
              },
            }),
          )
          setParts((prevParts) =>
            update(prevParts, {
              [itemPartIndex]: {
                sub_parts: {
                  [itemChapterIndex]: {
                    sub_parts: {
                      $splice: [[itemLessonIndex, 1]],
                    },
                  },
                },
              },
            }),
          )
        }
      } else {
        //DIFFERENT PART
        const itemChapter = getChapter(part)
        const targetChapter = getChapter(target)

        if (!itemChapter) {
          console.error('Unknown chapter')
          return
        }
        const itemChapterIndex = itemPart.sub_parts.indexOf(itemChapter)

        if (targetChapter) {
          //move lesson from one part to another
          const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
          const itemLessonIndex = itemChapter?.sub_parts.indexOf(part)

          setParts((prevParts) =>
            update(prevParts, {
              [targetPartIndex]: {
                sub_parts: {
                  [targetChapterIndex]: {
                    sub_parts: {
                      $splice: [[0, 0, part]],
                    },
                  },
                },
              },
              [itemPartIndex]: {
                sub_parts: {
                  [itemChapterIndex]: {
                    sub_parts: {
                      $splice: [[itemLessonIndex, 1]],
                    },
                  },
                },
              },
            }),
          )
        } else {
          setParts((prevParts) =>
            update(prevParts, {
              [targetPartIndex]: {
                sub_parts: {
                  $splice: [[0, 0, part]],
                },
              },
              [itemPartIndex]: {
                sub_parts: {
                  $splice: [[itemChapterIndex, 1]],
                },
              },
            }),
          )
        }
      }
    },
    [parts],
  )

  const pushLesson = useCallback(
    (lesson: LessonType, target: CoursePartType, type: string) => {
      if (type === 'introduction') {
        setIntroduction(lesson)
        setUsedLessonsIds([
          ...usedLessonsIds.filter((ulid) => ulid !== introduction?.id.toString()),
          lesson.id.toString(),
        ])
        return
      }
      if (type === 'evaluation') {
        if (!lesson.quiz) {
          setAlert({ severity: 'error', content: "L'évaluation ne peut être qu'une leçon quiz" })
        } else {
          setEvaluation(lesson)
          setUsedLessonsIds([
            ...usedLessonsIds.filter((ulid) => ulid !== evaluation?.id.toString()),
            lesson.id.toString(),
          ])
        }
        return
      }

      const targetPart = getPart(target)

      if (!targetPart) {
        console.error('Unknown part')
        return
      }
      const targetPartIndex = parts.indexOf(targetPart)
      const targetChapter = getChapter(target)
      if (!targetChapter) {
        console.error('Unknown chapter')
        return
      }

      const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
      const id = new Date().getTime()
      const newPart = {
        id: id,
        position: targetPart.sub_parts[targetChapterIndex]?.sub_parts?.length,
        lesson: lesson,
        name: lesson.name,
        parent: targetPart.sub_parts[targetChapterIndex],
      } as unknown as CoursePartType

      setParts((prevParts) =>
        update(prevParts, {
          [targetPartIndex]: {
            sub_parts: {
              [targetChapterIndex]: {
                sub_parts: {
                  $push: [newPart],
                },
              },
            },
          },
        }),
      )
      sleep(0.00000001)
        .then(() => {
          animatePart('add', id)
        })
        .catch((err) => console.log(err))
      setUsedLessonsIds([...usedLessonsIds, lesson.id.toString()])
    },
    [parts, usedLessonsIds],
  )

  const addPart = useCallback(
    (parent: CoursePartType | null, partName: string, partDuration?: number) => {
      if (!parent) {
        //ADD PART
        const id = new Date().getTime()
        setParts((prevParts) =>
          update(prevParts, {
            $push: [
              {
                id: id, //ensure unique id without colision
                position: 0,
                duration: partDuration,
                name: partName,
                sub_parts: [] as CoursePartType[],
              } as CoursePartType,
            ],
          }),
        )
        sleep(0.00000001)
          .then(() => {
            animatePart('add', id)
          })
          .catch((err) => console.log(err))
      } else {
        const targetPart = getPart(parent)
        if (!targetPart) {
          console.error('Unknown part')
          return
        }
        const targetPartIndex = parts.indexOf(targetPart)

        const targetChapter = getChapter(parent)

        if (!targetChapter) {
          //ADD CHAPTER
          const id = new Date().getTime()
          setParts((prevParts) =>
            update(prevParts, {
              [targetPartIndex]: {
                sub_parts: {
                  $push: [
                    {
                      id: id, //ensure unique id without colision
                      position: 0,
                      name: partName,
                      sub_parts: [] as CoursePartType[],
                    } as CoursePartType,
                  ],
                },
              },
            }),
          )
          sleep(0.00000001)
            .then(() => {
              animatePart('add', id)
            })
            .catch((err) => console.log(err))
        } else {
          // ADD LESSON
          const id = new Date().getTime()
          const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
          setParts((prevParts) =>
            update(prevParts, {
              [targetPartIndex]: {
                sub_parts: {
                  [targetChapterIndex]: {
                    sub_parts: {
                      $push: [
                        {
                          id: id, //ensure unique id without colision
                          position: 0,
                          name: partName,
                        } as CoursePartType,
                      ],
                    },
                  },
                },
              },
            }),
          )
          sleep(0.00000001)
            .then(() => {
              animatePart('add', id)
            })
            .catch((err) => console.log(err))
        }
      }
    },
    [parts],
  )

  const removePart = useCallback(
    (part: CoursePartType) => {
      const targetPart = getPart(part)
      if (!targetPart) {
        console.error('Unknown part')
        return
      }
      const targetPartIndex = parts.indexOf(targetPart)
      const targetChapter = getChapter(part)
      if (targetChapter) {
        const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
        const targetLessonIndex = targetChapter.sub_parts.indexOf(part)
        if (targetLessonIndex === -1) {
          //remove chapter
          animatePart('remove', parts[targetPartIndex].sub_parts[targetChapterIndex].id)
          sleep(1)
            .then(() => {
              setParts((prevParts) =>
                update(prevParts, {
                  [targetPartIndex]: {
                    sub_parts: {
                      $splice: [[targetChapterIndex, 1]],
                    },
                  },
                }),
              )
            })
            .catch((err) => console.log(err))
        } else {
          //remove lesson
          animatePart('remove', parts[targetPartIndex].sub_parts[targetChapterIndex].sub_parts[targetLessonIndex].id)
          sleep(1)
            .then(() => {
              setParts((prevParts) =>
                update(prevParts, {
                  [targetPartIndex]: {
                    sub_parts: {
                      [targetChapterIndex]: {
                        sub_parts: {
                          $splice: [[targetLessonIndex, 1]],
                        },
                      },
                    },
                  },
                }),
              )
            })
            .catch((err) => console.log(err))
        }
      } else {
        animatePart('remove', parts[targetPartIndex].id)
        sleep(1)
          .then(() => {
            setParts((prevParts) =>
              update(prevParts, {
                $splice: [[targetPartIndex, 1]],
              }),
            )
          })
          .catch((err) => console.log(err))
      }
    },
    [parts],
  )

  useEffect(() => {
    const courseLessons = parts.reduce((acc: CoursePartType[], part) => {
      part.sub_parts.map((chapter) => {
        chapter.sub_parts.map((lesson) => acc.push(lesson))
      })
      return acc
    }, [])
    const introevalids: string[] = []
    if (course?.introduction?.id) introevalids.push(course?.introduction?.id.toString())
    if (course?.evaluation?.id) introevalids.push(course?.evaluation?.id.toString())
    setUsedLessonsIds(courseLessons.map((part) => part?.lesson?.id.toString()).concat(introevalids))
  }, [parts])

  const animatePart = (type: string, partId: number) => {
    switch (type) {
      case 'switch':
        animate(
          `#part_${partId}`,
          {
            transform: ['scale(1.2)', 'scale(1.0)'],
          },
          { duration: 1.5 },
        )
        break
      case 'remove':
        animate(
          `#part_${partId}`,
          {
            transform: ['scale(1)', 'scale(0)'],
          },
          { duration: 1 },
        )
        break
      case 'add':
        animate(
          `#part_${partId}`,
          {
            transform: ['scale(0)', 'scale(1)'],
          },
          { duration: 1 },
        )
        break
      default:
        break
    }
  }

  const switchPart = useCallback(
    (part: CoursePartType, target: CoursePartType, dragIndex: number, hoverIndex: number) => {
      const targetPart = getPart(target)
      const itemPart = getPart(part)

      if (!targetPart || !itemPart) {
        console.error('Unknown part')
        return
      }

      const targetPartIndex = parts.indexOf(targetPart)
      const itemPartIndex = parts.indexOf(itemPart)

      if (targetPartIndex === itemPartIndex) {
        //SAME PART
        const targetChapter = getChapter(target)
        const itemChapter = getChapter(part)
        if (!targetChapter || !itemChapter) {
          console.error('Unknown chapter')
          return
        }
        const targetChapterIndex = targetPart.sub_parts.indexOf(targetChapter)
        const itemChapterIndex = itemPart.sub_parts.indexOf(itemChapter)
        if (targetChapterIndex === itemChapterIndex) {
          const itemLessonIndex = itemChapter?.sub_parts.indexOf(part)
          const targetLessonIndex = targetChapter?.sub_parts.indexOf(target)
          animatePart('switch', parts[itemPartIndex].sub_parts[itemChapterIndex].sub_parts[itemLessonIndex].id)
          setParts((prevParts) => {
            return update(prevParts, {
              [itemPartIndex]: {
                sub_parts: {
                  [itemChapterIndex]: {
                    sub_parts: {
                      $splice: [
                        [itemLessonIndex, 1],
                        [
                          targetLessonIndex,
                          0,
                          prevParts[itemPartIndex].sub_parts[itemChapterIndex].sub_parts[itemLessonIndex],
                        ],
                      ],
                    },
                  },
                },
              },
            })
          })
        } else {
          animatePart('switch', parts[itemPartIndex].sub_parts[itemChapterIndex].id)
          setParts((prevParts) => {
            return update(prevParts, {
              [itemPartIndex]: {
                sub_parts: {
                  $splice: [
                    [itemChapterIndex, 1],
                    [targetChapterIndex, 0, prevParts[itemPartIndex].sub_parts[itemChapterIndex]],
                  ],
                },
              },
            })
          })
        }
      } else {
        //DIFFERENT PART
        animatePart('switch', parts[dragIndex].id)
        setParts((prevParts) => {
          return update(prevParts, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevParts[dragIndex]],
            ],
          })
        })
      }
    },
    [parts],
  )

  if (!code_name) {
    return <Error error={'code_name inconnu'} />
  }

  if (error) {
    return <Error error={error} />
  }

  if (loading || !course) {
    return <Loading />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container item xs={12} ref={mainContainerRef}>
        <Grid item xs={9}>
          <Grid container direction="row" justifyContent="space-between" style={{ padding: theme.spacing(1) }}>
            <Grid item>
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setExpandAll(!expandAll)
                }}
              >
                {expandAll ? 'Réduire tout' : 'Développer tout'}
              </Typography>
              <Typography variant="body2">Durée totale: {secondsToDHMS(totalDuration, false)}</Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={
                      (JSON.stringify(course.parts) === JSON.stringify(parts) &&
                        course.introduction === introduction &&
                        course.evaluation === evaluation) ||
                      !(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))
                    }
                    onClick={handleSubmit}
                  >
                    Mettre à jour
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!(isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course))}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIsPartModalOpen(true)
                    }}
                  >
                    Ajouter une partie
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      setAnchorEl(event.currentTarget)
                    }}
                  >
                    <MdMoreVert size="20" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            wrap="nowrap"
            style={{
              maxHeight: `calc(100vh - ${maxHeight}px)`,
              overflow: 'scroll',
              padding: theme.spacing(1),
            }}
          >
            <Grid item xs={12} container spacing={1} direction="column" style={{ marginBottom: theme.spacing(1) }}>
              <IntroEval
                type="introduction"
                lesson={introduction}
                setLesson={() => {
                  setUsedLessonsIds(usedLessonsIds.filter((id) => id !== introduction?.id.toString()))
                  setIntroduction(null)
                }}
              />
              <IntroEval
                type="evaluation"
                lesson={evaluation}
                setLesson={() => {
                  setUsedLessonsIds(usedLessonsIds.filter((id) => id !== evaluation?.id.toString()))
                  setEvaluation(null)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Tree
                canDrag={isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course)}
                code_name={code_name}
                parts={parts}
                parent={null}
                switchPart={switchPart}
                movePart={movePart}
                removePart={removePart}
                addPart={addPart}
                expandAll={expandAll}
                refetch={refetch}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            padding: theme.spacing(1),
            background: theme.palette.background.default,
          }}
        >
          <Grid item style={{ marginBottom: theme.spacing(1) }}>
            <Grid item style={{ marginBottom: theme.spacing(1) }}>
              <Autocomplete
                id="course"
                size="small"
                defaultValue={code_name}
                options={courses.map((course) => course.code_name)}
                onChange={(_, value) => {
                  if (value) {
                    setSelectedCodeName(value)
                  }
                }}
                renderInput={(params) => {
                  return <TextField {...params} />
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: theme.spacing(1) }}>
              <FormControl style={{ flexGrow: 1, display: 'flex' }}>
                <Select
                  fullWidth
                  value={lessonType}
                  onChange={(event) => {
                    setLessonType(event.target.value as string)
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    PaperProps: {
                      style: {
                        maxHeight: 56 * 5.5 + 10,
                      },
                    },
                  }}
                >
                  {[
                    'Tout',
                    'Non utilisées',
                    'Vidéo',
                    'Document',
                    'Textuelle',
                    'EPP',
                    'Quiz',
                    'Synthèse',
                    'Tâche',
                    'Dactylo',
                  ].map((status) => {
                    return (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                placeholder="Rechercher une leçon"
                onChange={(e) => searchLessons(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch size={22} />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            <Grid
              item
              style={{
                width: '100%',
                maxHeight: `calc(100vh - ${maxHeight + 80}px`,
                overflow: 'scroll',
              }}
            >
              {filteredLessons.filter((lesson) => {
                if (lessonType === 'Tout') return true
                if (lessonType === 'Non utilisées') return !usedLessonsIds.includes(lesson.id.toString())
                if (lessonType === 'Vidéo') return lesson.video !== null
                if (lessonType === 'Document') return lesson.document !== null
                if (lessonType === 'EPP') return lesson.epp !== null
                if (lessonType === 'Tâche') return lesson.task !== null
                if (lessonType === 'Quiz') return lesson.quiz !== null
                if (lessonType === 'Dactylo') return lesson.dactylo !== null
                if (lessonType === 'Textuelle') return lesson.text !== null && lesson.text.type === 'LESSON'
                if (lessonType === 'Synthèse') return lesson.text !== null && lesson.text.type === 'SYNTHESIS'
                return true
              })?.length > 0 ? (
                filteredLessons
                  .filter((lesson) => {
                    if (lessonType === 'Tout') return true
                    if (lessonType === 'Non utilisées') return !usedLessonsIds.includes(lesson.id.toString())
                    if (lessonType === 'Vidéo') return lesson.video !== null
                    if (lessonType === 'Document') return lesson.document !== null
                    if (lessonType === 'EPP') return lesson.epp !== null
                    if (lessonType === 'Tâche') return lesson.task !== null
                    if (lessonType === 'Quiz') return lesson.quiz !== null
                    if (lessonType === 'Dactylo') return lesson.dactylo !== null
                    if (lessonType === 'Textuelle') return lesson.text !== null && lesson.text.type === 'LESSON'
                    if (lessonType === 'Synthèse') return lesson.text !== null && lesson.text.type === 'SYNTHESIS'
                    return true
                  })
                  // .filter((lesson) => {
                  //   if (lesson.video) {
                  //     return lesson.video.video_set !== null
                  //   } else {
                  //     return true
                  //   }
                  // })
                  .sort((a, b) => {
                    if (lessonType === 'Synthèse') {
                      return a.name.localeCompare(b.name)
                    }
                    if (lessonType === 'Vidéo') {
                      return a.video.number - b.video.number
                    }
                    return 0
                  })
                  .map((lesson) => {
                    return (
                      <LessonCardShort
                        key={lesson.id}
                        lesson={lesson}
                        pushLesson={pushLesson}
                        canDrag={isAuthorized(['ADMIN', 'PRODUCTION', 'SEO']) || isCourseManager(course)}
                        isUsed={!usedLessonsIds.includes(lesson.id.toString())}
                      />
                    )
                  })
              ) : (
                <Grid item style={{ padding: theme.spacing(1) }}>
                  <Typography variant="caption">Aucune leçon trouvée</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal
        size="xs"
        title="Ajouter une partie"
        open={isPartModalOpen}
        onClose={() => {
          setIsPartModalOpen(false)
        }}
      >
        <AddPartModal type="part" parent={null} addPart={addPart} setIsPartModalOpen={setIsPartModalOpen} />
      </CustomModal>
      <CustomModal
        size="sm"
        title="Importer le séquencier"
        open={importModal}
        onClose={() => {
          setImportModal(false)
        }}
      >
        <ImportCoursePartsForm
          course={course}
          handleClose={() => {
            setImportModal(false)
          }}
          refetch={refetch}
        />
      </CustomModal>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            setImportModal(true)
            setAnchorEl(null)
          }}
        >
          Importer depuis une autre formation
        </MenuItem>
        <MenuItem
          onClick={async () => {
            setAnchorEl(null)
            if (course?.id) {
              await downloadCoursePlanExcel({ variables: { where: { id: { eq: parseFloat(`${course.id}`) } } } })
            }
          }}
        >
          Télécharger séquencier excel
        </MenuItem>
      </Menu>
    </DndProvider>
  )
}

export default Plan
